// imports
@import "src/scss/variable.scss";

.order-detail-wrapper {
  .order-items-box {
    counter-reset: section;
    padding-inline-start: 0px;
  }
  
  .orderdetail-listing-text { 
    list-style-type: none !important;
    position: relative;
    background-color:white;
    padding: 10px;
    margin-top: 2px;
  }
  
  .orderdetail-listing-text:first-child{ border-top-left-radius:10px; border-top-right-radius: 10px; } 
  .orderdetail-listing-text:last-child{ border-bottom-left-radius: 10px; border-bottom-right-radius:10px; }
  
  .orderdetail-flex-box {
    list-style-type: none !important;
  }
  
  .orderdetail-orderinfo-small {
    padding: 0px !important;
  }
  
  .order-detail-page-link {
    font-size: 12px;
    color: $link-color !important;
    text-decoration: underline;
    cursor: pointer;
    overflow-wrap: anywhere;
    
    &.delivery-partner-link {
      font-weight: 400;
    }
  }
  
  .orderdetail-orderremark-text {
    font-weight: 800;
    font-size: 12px;
    color: $main-color !important;
    word-break: break-word;
    margin-bottom: 0;
  }
  
  .delivery-status-timeline-section {
    .timeline-box {
      max-height: 200px;
      margin: 4px 5px 40px;
      padding-right: 8px;
      color: #0A2540;
      font-size: 14px;
      overflow-y: scroll;
      
      .each-timeline {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid $grey600;
        
        .status-section {
          display: flex;
          flex-direction: column;
          max-width: 85%;
          
          .status-label {
            overflow-wrap: anywhere
          }
          
          .delivery-info-label {
            color: $cGray;
          }
        }
      }
    }
  }
}