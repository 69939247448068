// imports
@import "src/scss/variable.scss";
@import "src/scss/mixin.scss";
@import "src/scss/responsive.scss";

//Fonts
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

.store-information-container {
  // padding: 20px 0;
  // background-color: $background-color;
  // margin-top: 20px;
  // border: 0px;
  // box-shadow: none;
  padding: 0 0 60px;
  .container-section-top {
    margin-top: 1rem;
  }

  .container-section {
    margin-top: 1.5rem;
    
    .store-qr-display {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .container-section-button {
    margin-top: 1.5rem;
    width: 100%;
  }

  .title-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    .tooltip {
      position: relative;
      display: inline-block;
  
      .tooltiptext {
        visibility: hidden;
        width: 100px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        color: green;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -53px;
    
        &::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #fff transparent transparent transparent;
        }
      }
    }
  }

  .banner {
    width: 100%;
    height: 200px;
  }

  .qr-code {
    width: 70%;
    aspect-ratio: 1;
  }

  .img-wrapper {
    margin: 0 auto;
    // width: 100%;
    // height: 200px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    border: 1px solid;
    border-radius: 8px;
    border-color: $secondary-color !important;

    .img-functional-header,
    .img-functional-footer {
      display: none;
    }

    &:hover {
      .img-functional-header {
        display: block;
        text-align: right;

        .icon-cancel {
          cursor: pointer;
          color: #c6cbd1;
          padding: 5px;
        }
      }

      .img-functional-footer {
        display: flex;
        flex-grow: 1;
        width: 100%;
        height: 40px;
        align-items: center;
        justify-content: center;
        color: #c6cbd1;
        background-color: gray;
        margin-top: 122px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        cursor: pointer;

        * {
          cursor: pointer;
        }

        .view {
          padding: 0 5px;
        }
      }
    }
  }

  .store-url {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: $link-color !important;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
  }

  .store-upload-image {
    margin-top: 10px;
    border: 1px solid $background-color;
    background-color: #fff;
  }

  .store-dropzone {
    background: white;
    border: 1px solid #c2c2c2;
    border-radius: 3px;
    text-align: center;
    // padding: 10px 50px;
    height: 8.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: gray;
    flex-direction: column;
    justify-content: center;
  }

  .store-info-button {
    margin-right: 0px;
    border-radius: 25px;

    &.MuiButton-root {
      font-family: "DM Sans", sans-serif;
      font-weight: normal;
      text-transform: none;
    }

    &.red {
      color: #d32f2f;
      border-color: #d32f2f;
    }
  }

  .store-info-next-button {
    width: 100%;
    margin-top: 20px;
    font-family: "DM Sans", sans-serif;
    text-transform: capitalize;
    border-radius: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 40px;
    padding-right: 40px;

    &.MuiLoadingButton-root {
      background-color: $primary-color;
    }

    &.MuiLoadingButton-loading {
      background-color: #f8f8f8;
    }
  }


  .tooltip {
    position: relative;
    display: inline-block;
    margin-right: 1.5rem;
  }

  .tooltip:hover {
    cursor: pointer;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: $primary-color;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }

  .input-box {
    input {
      background-color: white;
    }
    border-radius: 5px;
    margin-top: 10px !important;
    width: 100%;
  }
}
