// imports
@import './scss/variable.scss';
@import './scss/mixin.scss';
@import './scss/responsive.scss';

//Fonts
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.MuiOutlinedInput-root {
  background-color: #fff;
}
//General

.body-padding {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 6.85vh;
  background-color: $background-color;
  max-width: none;
  margin: 0 auto;
  height: 86.3vh;
  position: relative;
  overflow-x: hidden;
  @include desktop {
    max-width: 450px;
  }
}

.body-login-padding {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  background-color: $background-color;
  max-width: none;
  height: 86.3vh;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  @include desktop {
    max-width: 450px;
  }
}

.body-padding-with-footer {
  padding-left: 15px;
  padding-right: 15px;
  background-color: $background-color;
  height: calc(100vh - 12.7vh);
  max-width: none;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  @include desktop {
    max-width: 450px;
  }
}

.new-body-padding-with-footer {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 80px;
  background-color: $background-color;
  max-width: none;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  @include desktop {
    max-width: 450px;
  }
}

.main-title {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: $main-color !important;
  text-transform: capitalize;
  margin-top: 5px;
  margin-bottom: 5px;
}

.backbutton-styling {
  fill: $main-color !important;
  stroke: $main-color !important;
  color: $main-color !important;
  font-size: 24px !important;
  margin-top : 16px;
}

.nextbutton-styling {
  fill: black !important;
  stroke: black !important;
  color: black!important;
  font-size: 24px !important;
}

.push-right {
  margin-left: auto;
}

.advancebox-row {
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
//Search Box
.search-box{
  width: 100%;
  position: relative;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.search-input{
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  border: 0px;
  border-radius:5px 0 0 5px ;
  border-right: none;
  outline: none;
  font-size: 15px;
  color: $main-color;
  font-weight:500;
  background: #ececf2;
}

.search-button{
  text-align: center;
  width: 40px;
  outline: none;
  cursor: pointer;
  border: 0px;
  border-radius: 0 5px 5px 0 ;
  border-left: none;
  background: #ececf2;
  font-size: 20px;
}

.container{
  width: 100%;
}

//Header

.ewarung-header {
  align-items: center;
  background-color: #0A2540;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  height: 6.35vh;
  width: 100%;
  z-index: 2;
  @include desktop {
    max-width: 450px;
  }
}

.ewarung-language-icon {
   margin-left: auto;
}

.ewarung-logo {
  vertical-align: middle;
  border-style: none;
  width: 20%;
  padding-left: 15px;
  @include desktop {
    width: 20%;
  }
}

//Footer 
.ewarung-footer {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 6.35vh;
  width: 100%;
  z-index: 2;
  position: fixed;
  bottom: 0;
  max-width: none;
  margin: 0 auto;
  position: relative;
  @include desktop {
    max-width: 450px;
  }
}

.footer-icon-container {
  margin: 0 auto;
  text-align: center;
}

.footer-label {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 400;
  font-size: 11px;
  color: $main-color;
  text-transform: capitalize;
  text-decoration: none;
  &.active {
    color: $primary-color;
  }
}

.footer-icon-styling {
  color: $secondary-color;
  font-size: 1.2rem;

  &.active {
    color: $primary-color;
  }
}

//Login Page
.order-login-container {
  padding: 20px;
  background-color: $background-color;
  margin-top: 20px;
  border: 0px;
  box-shadow: none;
}
  

//Order Home Page

.orderhome-body-section {
  padding-top:5px;
  padding-bottom: 25px;
}

.orderhome-tabs-text {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  color: $main-color !important;
  text-transform: capitalize;
  display: flex;
  margin:auto;
}

.orderhome-order-holder {
  margin-top: 2px;
  margin-bottom: 0px;
}

.orderhome-order-box {
  background-color:white;
  padding: 10px;
  border-radius: 8px;
}

.orderhome-flex-box {
  padding: 0;
  display: flex;
  gap: 1rem;
}

.orderhome-grid-box {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.orderhome-grid-box-2 {
  display: grid;
  grid-template-columns: 2fr 1.3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.orderhome-orderinfo-small {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #636363 !important;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
}

.orderhome-orderinfo-yes {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
}

.orderhome-orderinfo-no {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $secondary-color !important;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
}

.orderhome-orderinfo-time {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $secondary-color !important;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: right;
}

.orderhome-orderinfo-large {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: $main-color !important;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: left;
}

.orderhome-orderinfo-small-2 {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $secondary-color !important;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
}

.orderhome-orderinfo-small-3 {
  font-family: 'DM Sans', sans-serif;
  font-weight: 800;
  font-size: 14px;
  color: $secondary-color !important;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
}

.orderhome-orderinfo-extralarge {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: $main-color !important;
  text-transform: capitalize;
  margin-top: 5px;
  margin-bottom: 5px;
}

.orderhome-orderinfo-mainpickup {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  color: white !important;
  text-transform: capitalize;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  background-color: $primary-color;
  border-radius: 16px;
  width: 125px;
}

.orderhome-orderinfo-pickup {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: white !important;
  text-transform: capitalize;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  background-color: $primary-color;
  border-radius: 16px;
}

.orderhome-orderinfo-pickup-2 {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  background-color: $primary-color;
  border-radius: 19px;
  padding: 6px;
}

.orderhome-orderinfo-text {
  font-family: 'DM Sans', sans-serif;
  font-weight: 800;
  font-size: 12px;
  color: $main-color !important;
  word-break: break-word;
}

.orderhome-orderinfo-text-2 {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: $main-color !important;
}

.orderhome-orderinfo-key {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: black !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.orderhome-orderinfo-title {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: $main-color !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.orderhome-orderinfo-quantity {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: $main-color !important;
  margin-top: 8px;
  margin-bottom: 5px;
  text-align: center;
}

.orderhome-orderinfo-quantitybox {
  border: 1px solid rgb(227, 223, 223);
  padding: 5px;
  height: 40px;
  width: 40px;
  border-radius: 10px;
}

// View Order Page

.orderdetail-grid-box {
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

.orderdetail-flex-box {
  padding: 0;
  display: inline-flex;
  gap: 0.3rem;
  width: 250px;
  flex-wrap: wrap;
}

.orderdetail-orderinfo-remarkbox {
  margin-top:10px;
}

.orderdetail-orderinfo-small {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $secondary-color !important;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
  word-break: break-word;
}


.orderdetail-download-buttonbox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 5px;
}

.orderdetail-download-icon {
  padding: 5px;
  font-size: 1rem;
}

.order-remark-box {
  background-color:white;
  padding: 10px;
  margin-top: 2px;
  margin-bottom: 10px;
  border-radius:10px; 
  border: 3px solid rgba(0, 140, 220,0.2);
}

.orderview-button-bottom {
  position: sticky;
  bottom: 0;
  max-width: none;
  margin: 0 auto;
  position: relative;
  @include desktop {
    max-width: 480px;
  }
}

.orderview-button-holder {
  background-color: white;
  box-shadow: 0px -1px 25px 0px rgba(0,0,0,0.15);
  width: 100%;
  position: fixed;
  bottom: 0;
  max-width: 450px;
}

.orderview-button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  padding-top: 10px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 10px;
}

.orderview-button-box {
  margin: auto;
}

.orderview-single-button-box {
  margin: auto;
  padding: 10px;
}

.orderview-reject-button {
  font-family: 'DM Sans', sans-serif !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  border-radius: 40px !important;
  width: 100% !important;
  height: 45px !important;
  margin-top:0px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
  &.MuiLoadingButton-root {
      background-color: white;
      color: $primary-color;
      border: 1px solid $primary-color;
  }
  
  &.MuiLoadingButton-loading {
      background-color: #F8F8F8;
      color: #F8F8F8;
      border: 1px solid rgba(0, 0, 0, 0.26);
  }
  
  &.css-flm0so-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
    background-color: #F8F8F8;
    color: rgba(0, 0, 0, 0.26);
    border: 1px solid rgba(0, 0, 0, 0.26);
  }
}

.orderview-confirm-button {
  width: 100%;
  margin-top: 20px;
  font-family: "DM Sans", sans-serif !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  border-radius: 40px !important;
  width: 100% !important;
  height: 45px !important;
  margin-top:0px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
  &.MuiLoadingButton-root {
      background-color: $primary-color;
      color: white; 
      border: 1px solid $primary-color;
  }
  
  &.MuiLoadingButton-loading {
      background-color: #F8F8F8;
      color: rgba(0, 0, 0, 0.26);
      border: 1px solid rgba(0, 0, 0, 0.26);
  }
  
  &.css-1pkfx9i-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
    background-color: #F8F8F8;
    color: rgba(0, 0, 0, 0.26);
    border: 1px solid rgba(0, 0, 0, 0.26);
  }
}

.orderdetail-download-button {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  width: 100% !important;
  margin-top: 10px !important;
  margin-bottom: 20px;
  border-radius: 40px !important;
  padding: 4px 6px !important;
  &.MuiLoadingButton-root {
      background-color: white;
      color: $primary-color;
      border: 1px solid $primary-color;
  }
  
  &.MuiLoadingButton-loading {
      background-color: #F8F8F8;
      color: rgba(0, 0, 0, 0.26);
      border: 1px solid rgba(0, 0, 0, 0.26);
  }
  
  &.css-1pkfx9i-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
    background-color: #F8F8F8;
    color: rgba(0, 0, 0, 0.26);
    border: 1px solid rgba(0, 0, 0, 0.26);
  }
}

.settings-save-button {
  width: 100%;
  margin-top: 20px;
  font-family: "DM Sans", sans-serif !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  border: 1px solid $primary-color !important;
  border-radius: 40px !important;
  width: 100% !important;
  height: 45px !important;
  margin-top:0px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
  background-color: $primary-color;
  color: white; 
}

.orderview-confirm-noload-button {
  width: 100%;
  margin-top: 20px;
  font-family: "DM Sans", sans-serif;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  background-color: $primary-color !important;
  border: 1px solid $primary-color !important;
  background-color: $primary-color !important;
  border-radius: 40px !important;
  color: white !important;
  width: 100% !important;
  height: 45px !important;
  margin-top:0px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
}

.orderview-confirm-nopt-button {
  padding-bottom: 8px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  background-color: $primary-color !important;
  border: 1px solid $primary-color !important;
  border-radius: 40px !important;
  color: white !important;
  width: 100% !important;
  height: 36px !important;
  text-transform: capitalize !important;
}


.orderview-orderinfo-progress {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 10px;
  color: white !important;
  text-transform: capitalize;
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: center;
  background-color: $main-color;
  border-radius: 16px;
  width: fit-content ;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
}

.vieworder-warning-box {
  background-color:#E74C3C;
  padding:10px;
  border-radius:10px;
  margin-bottom:10px;
}

.vieworder-warning-text {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: white !important;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
  word-break: break-word;
}

//Order Complete
.ordercomplete-button-holder {
  background-color: white;
  box-shadow: 0px -1px 25px 0px rgba(0,0,0,0.15);
  width: 100%;
  position: fixed;
  bottom: 0;
  max-width: 450px;
}
.ordercomplete-button-padding {
  padding: 10px;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 93vh;
  overflow: hidden;
  background-color: $background-color;
}

.center-loader{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.ordercomplete-title {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: $main-color !important;
  text-transform: capitalize;
  margin-top: 0px;
  margin-bottom: 24px;
}

.ordercomplete-icon-holder {
  margin-bottom: 24px;
}

.ordercomplete-text {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: $main-color !important;
  text-transform: capitalize;
  margin-top: 0px;
  margin-bottom: 0px;
}

.riderrequest-textbox {
  margin-top: 4px;
  margin-bottom: 24px;
}

.ordercomplete-link {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: $primary-color !important;
  background-color: $background-color !important;
  border: 1px solid $background-color !important;
  text-transform: capitalize;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: underline;
}

.menu-image {
  width: 100px;
  min-height: 100px;
  object-fit: cover;
}

.menu-grid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.menu-info-box {
  margin-left: 10px;
}

.add-new-button {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: $primary-color !important;
  text-transform: capitalize;
  margin-top: 14px;
  margin-bottom: 0px;
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input { 
  padding: 12px!important;
}

.addproduct-textfield {
  margin-top: 10px;
  width: 100%;
}

.addproduct-double-textfield {
  margin-top: 10px;
  width: 100%;
  margin-left: 5px;
}

.addproduct-confirm-button {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: 8px !important;
  border: 1px solid $primary-color !important;
  border-radius: 5px !important;
  color: white !important;
  text-transform: capitalize !important;
  width: 100% !important;
  height: 36px !important;
  margin-top: 20px !important;
  margin-left: 10px !important;
    &.MuiLoadingButton-root {
        background-color: $primary-color ;
    }
              
    &.MuiLoadingButton-loading {
        background-color: #F8F8F8;
    }
}

.addproduct-singleconfirm-button {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: 8px !important;
  background-color: $primary-color !important;
  border: 1px solid $primary-color !important;
  border-radius: 5px !important;
  color: white !important;
  text-transform: capitalize !important;
  width: 100% !important;
  margin-top: 20px !important;
}

.addproduct-rejectreason-button {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: 8px !important;
  background-color: $primary-color !important;
  border: 1px solid $primary-color !important;
  border-radius: 5px !important;
  text-transform: none !important;
  color: white !important;
  width: 100% !important;
  margin-top: 20px !important;
}

.addproduct-back-button {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: $primary-color !important;
  text-transform: capitalize !important;
  width: 100% !important;
  height: 36px !important;
  margin-top: 20px !important;
  border: 1px solid $primary-color !important;
  border-radius: 5px !important;
}

.addproduct-doubleback-button {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: $primary-color !important;
  text-transform: capitalize !important;
  width: 100% !important;
  height: 36px !important;
  margin-top: 20px !important;
  border: 1px solid $primary-color !important;
  border-radius: 5px !important;
}

.addproduct-add-iconbox {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: 15px !important;
  background-color: $primary-color !important;
  border: 1px solid $primary-color !important;
  border-radius: 5px !important;
  color: white !important;
  text-transform: capitalize !important;
  width: 100% !important;
  height: 20px !important;
  margin-top: 10px !important;
}

.addproduct-remove-iconbox {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: $primary-color !important;
  text-transform: capitalize !important;
  width: 100% !important;
  height: 20px !important;
  border: 1px solid $primary-color !important;
  border-radius: 5px !important;
  margin-top: 10px !important;
  padding: 15px !important;
}

.upload-image-box {
  border: 1px solid $background-color;
  background-color: #fff;
}

.upload-icon {
  margin: auto !important;
  text-align: center !important;
  height: 60px !important;
  width:60px !important;
  color: darkgray !important;
}

.invisible-button {
  background-color: white !important;
  border: 1px solid $background-color !important;
  box-shadow: none !important;
  color: darkgray !important;
}

.plusminus {
  display: inline-block;
  position: relative;
  font-size: 0;
  overflow: hidden;
  border-radius: 3px;
  width: 44px;
  height: (44px*3);
  button {
    display: block;
    width: 44px;
    height: 44px;
    position: absolute;
    margin: 0;
    padding: 0;
    border: 0;
    background: #000;
    color: #fff;
    font-size: (44px * 0.7);
    line-height: 1;
    font-family: bonds, sans-serif;
    cursor: pointer;
    &:focus {
      outline: none;
      color: #2bebaf;
    }
    &:disabled {
      background: #999;
      color: #ccc;
      cursor: default;
    }
    &:first-child {
      bottom: 0;
      &:before {
        content: "-";
      }
    }
    &:last-child {
      top: 0;
      &:before {
        content: "+";
      }
      &:focus {
        color: #ff6367;
      }
    }
  }
  input[type="number"] {
    position: absolute;
    width: 44px;
    height: 44px;
    top: 44px;
    margin: 0;
    padding: 0 0 16px;
    border: 0;
    font-size: 18 px;
    font-weight: bold;
    text-align: center;
    -moz-appearance:textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &:after {
    content: "QTY";
    position: absolute;
    font-size: 10px;
    left: 0;
    right: 0;
    line-height: 1;
    padding: 4px 0 4px 0;
    width: 44px;
    margin: 0 auto;
    border-top: 1px solid #ccc;
    color: #888;
    text-align: center;
    bottom: 34%;
  }
  &.horiz {
    width: 132px;
    height: 44px;
    button {
      &:first-child {
        left: 0;
      }
      &:last-child {
        right: 0;
      }
    }
    &:after {
      bottom: 0;
    }
    input[type="number"] {
      position: absolute;
      top: 0;
      left: 44px;
    }
  }
  &.stacked {
    width: (44px*2);
    height: (44px*2);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    &:after {
      width: 100%;
      bottom: 50%;
    }
    button {
      float: none;
      top: auto;
      bottom: 0;
      &:first-child {
        left: 0;
      }
      &:last-child {
        right: 0;
      }
    }
    input[type="number"] {
      top: 0;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 1px solid #eee;
      width: 100%;
      &:after {
        width: 100%;
      }
    }
  }
}

.addproduct-extra-text {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: $secondary-color !important;
  margin-top: 15px;
  margin-bottom: 10px;
}

.addOnlist-box {
  padding-top: 20px;
}

.productdetail-back-button {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: $primary-color !important;
  text-transform: capitalize !important;
  width: 100% !important;
  height: 36px !important;
  margin-top: 0px !important;
  border: 1px solid $primary-color !important;
  border-radius: 5px !important;
}

.addproduct-addicon-styling {
  fill: white !important;
  stroke: white !important;
  color: white !important;
  font-size: 1rem !important;
  margin-right: 5px;
}

.addproduct-removeicon-styling {
  fill: $primary-color !important;
  stroke: white !important;
  color: white !important;
  font-size: 1rem !important;
  margin-right: 5px;
}


.addproduct-button-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.addproduct-number-text {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: $main-color !important;
  text-transform: capitalize !important;
  text-align: center;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 40px;
}

.addproduct-number-container {
  width: 100%;
  display: flex;
  gap: 0.4rem;
  overflow-x: auto;
}

.addproduct-number-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.addproduct-number-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.preview-image-container {
  width: 100%;
}

.preview-image-container-2 {
  width: 100px;
  border: 1px solid black ;
}

.selectlanguage-select-button {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  margin-top: 14px !important;
  margin-bottom: 0px !important;
  border-radius: 10px !important;
  text-align: left !important;
  min-width: 64px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

 .currentstatus-select-button {
    font-family: 'DM Sans', sans-serif !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
    margin-top: 14px !important;
    margin-bottom: 0px !important;
    border-radius: 10px !important;
    text-align: left !important;
    min-width: 64px;
    width: 100%;
    padding-left : 10px;
  }

.flag-image-container {
  width: 35%;
  padding: 10px;
}


.settings-div {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  margin-top: 14px !important;
  margin-bottom: 0px !important;
  text-align: left !important;
  min-width: 64px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid $grey600;
}

.setttings-icon {
  padding: 15px;
  font-size: 14px;
}

.settings-text {
  font-family: 'DM Sans', sans-serif;
  font-weight: 800;
  font-size: 14px;
  color: $main-color !important;
  margin-left: 8px;
}

.app-body {
  max-width: 450px;
  margin: 0 auto;
  position: relative;
  background-color: $background-color;
  min-height: 100vh;
  overflow-y: scroll;
  font-family: "DM Sans", sans-serif !important;
}

.loading-symbol-wrapper {
  margin: auto;
  text-align: center;
}

.store-opening-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 10px;
}

.storeopening-textfield {
  margin-top: 10px;
  margin-left: 5px;
  width: 100%;
  background-color: white;
}

.storeopening-textlabel {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.storeopening-box {
  padding-right: 5px;
}

.storeopening-days-select {
  margin-top : 10px;
  margin-bottom : 10px;
  margin-left : 5px;
  min-width: 33%;
}

.reject-modal {
  padding: 10px !important;
}

.icon-cancel {
  text-align: right;
  font-size: 1.5rem;
  margin-top: 15px;
}

.cancel-modal-header {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 18px !important;
  color: $main-color !important;
  text-transform: capitalize;
  margin-top: 5px;
  margin-bottom: 5px;
}

.search-bar {
        min-width: 200px;
        width: 100%;
        display: inline-block;
        background-color: #ececf2;
        box-sizing: border-box;
        padding: 0 20px;
        border-radius: 5px;
        border: none;
  
        .MuiInputBase-input {
          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #425466;
            opacity: 1; /* Firefox */
          }
  
          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #425466;
          }
  
          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #425466;
          }
        }
  
        .search-bar-input {
          width: calc(100% - 25px);
          font-family: 'DM Sans', sans-serif;
        }
  
        .search-bar-logo {
          .logo {
            position: relative;
            top: 7px;
            color: #c6cbd1;
          }
        }
      }
    
    
  .update-input-box {
    background-color: white;
    border-radius: 5px;
    margin-top: 10px !important;
    width: 100%;
  }
  
  .img-fluid {
    width: 100%;
  }