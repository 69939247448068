// imports
@import "../../../scss/variable.scss";
@import "../../../scss/mixin.scss";
@import "../../../scss/responsive.scss";

.layout-body-padding {
  padding: 65px 20px 10px;
  background-color: $background-color;
  min-height: 100vh;
  max-width: none;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  @include desktop {
    max-width: 450px;
  }
}

.layout-body-padding-with-footer {
  padding-top: 65px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 60px;
  background-color: $background-color;
  height: 88vh;
  max-width: none;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  @include desktop {
    max-width: 450px;
  }
}

.layout-new-body-padding-with-footer {
  padding-top: 6.85vh;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 6.85vh;
  background-color: $background-color;
  height: 86.3vh;
  max-width: none;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  @include desktop {
    max-width: 450px;
  }
}

.layout-backbutton-styling {
  fill: $main-color !important;
  stroke: $main-color !important;
  color: $main-color !important;
  font-size: 1.2rem !important;
}

.layout-button-bottom {
  position: sticky;
  bottom: 0;
  max-width: none;
  margin: 0 auto;
  position: relative;
  @include desktop {
    max-width: 480px;
  }

  .layout-button-bottom-section {
    background-color: white;
    height: 56px;
    box-shadow: 0px -1px 25px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    position: fixed;
    bottom: 0;
    max-width: 450px;

    .layout-button-box {
      margin: auto;
      padding: 10px;
    }

    .confirm-button {
      padding: 8px 40px;
      border-radius: 40px;
      width: 100%;
      height: 36px;
      text-transform: capitalize;
    }
  }
}
